import { useEffect, useState } from 'react';
// import { useState } from 'react';
import Header from './components/Header';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CarrierServices from './CarrierServices/CarrierServices';
import Dev from './components/Dev';
import Tech from './Tech/Tech';
import TechAudit from './Tech/Audit';
import Risk from './Risk/Risk';
import RiskFraudContacts from './Risk/FraudContacts/FraudContacts';
import UBox from './Operations/Ubox';



function App() {
	const [profile, setProfile] = useState({});

	const validateLogin = async () => {
		const res = await fetch(`https://api.home.r2logistics.com/v1/login/validate?path=${encodeURIComponent(window.location.pathname)}`, {
			method: 'GET',
			credentials: 'include',
			headers: new Headers({
				Accept: 'application/json'
			})
		});
		const data = await res.json();

		switch (await res.status) {
			case 200:
				console.log('Authentication validation successful');
				console.log(JSON.stringify(data));//DEBUG
				setProfile(data);
				break;
			case 401:
			case 403:
				console.error(JSON.stringify(data))
				//TODO: Find better solution
				document.location = 'https://api.home.r2logistics.com/v1/login';
				break;
			default:
				//TODO: Error to user
				console.error(JSON.stringify(data))
		}
		return;
	};

	useEffect(() => { //TODO: Add try/catch
		validateLogin();
	}, []);



	return (
		<Router>
			{/* <Redirect to="/CarrierServices" /> */}
			<div className="container">
				<Header profile={profile} />
				<Route path='/CarrierServices'    component={CarrierServices} />
				<Route path='/Tech'               component={Tech} />
				<Route path='/Dev'                component={Dev} />
				<Route exact path='/Risk'               component={Risk} />
				<Route path='/Risk/FraudContacts' component={RiskFraudContacts} />
				<Route path='/Tech/Audit'         component={TechAudit} />
				<Route path='/Operations/UBox'    component={UBox} />
			</div>
		</Router>
	);
}

export default App;
