import Table from './Table';
import Modal from './Modal';
import { useEffect, useState, useCallback } from 'react';
import styles from './FraudContacts.module.css';

const FraudContacts = () => {
	const [contacts, setContacts] = useState({
		rows: [],
		columns: []
	});

	const [searchInput, setSearchInput] = useState("");
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortColumn, setSortColumn] = useState('created');
	const [sortDesc, setSortDesc] = useState(true);
	const [statistics, setStatistics] = useState({
		uniqueEmails: 0,
		uniquePhones: 0,
		total: 0
	});

	const fetchContacts = useCallback(async () => {
		let hostname = window.location.hostname;
		let prefix = hostname.includes('dev-') ? 'dev-' : '';
		let url = `https://${prefix}api01.r2logistics.com/api/v1/risk/fraud/contacts`;

		let params = {
			page,
			page_size: pageSize,
		}

		if (searchInput !== '') {
			params.q = searchInput;
		}
		if (sortColumn !== '') {
			params.sort = sortColumn;
		}
		if (sortDesc) {
			params.desc = null;
		}

		url += '?' + new URLSearchParams(params).toString();

		const res = await fetch(url, {
			method: 'GET',
			credentials: 'include',
			headers: new Headers({
				Accept: 'application/json',
			})
		});
		const data = parseContacts(await res.json());

		//TODO: Notify on errors
		//TODO: Change credentials to Same-Site for production
		switch (await res.status) {
			case 200:
				setContacts(data);
				break;
			case 401:
			case 403:
				console.error('Received authentication error when trying to get contacts.');
				//TODO: reauthorize
				break;
			default:
				console.error('Something else when trying to get contacts.');
			//TODO: something wonderful
		}
		return;
	}, [searchInput, page, pageSize, sortColumn, sortDesc]);

	useEffect(() => {
		fetchContacts();
	}, [fetchContacts]);

	const updatePreferenceCookie = useCallback(() => {
		const preferences = {
			pageSize,
			sortColumn,
			sortDesc
		};
		document.cookie = `fraudContactsPreferences=${JSON.stringify(preferences)}; path=/; max-age=${60 * 60 * 24 * 365}`;
	}, [pageSize, sortColumn, sortDesc]);

	useEffect(() => {
		if (pageSize !== 10 || sortColumn !== 'created' || sortDesc !== true) {
			updatePreferenceCookie();
		}
	}, [pageSize, sortColumn, sortDesc, updatePreferenceCookie]);

	const readPreferenceCookie = useCallback(() => {
		const cookie = document.cookie.split('; ').find(row => row.startsWith('fraudContactsPreferences='));
		if (cookie) {
			const preferences = JSON.parse(cookie.split('=')[1]);
			setPageSize(preferences.pageSize);
			setSortColumn(preferences.sortColumn);
			setSortDesc(preferences.sortDesc);
		}
	}, []);

	useEffect(() => {
		readPreferenceCookie();
	}, [readPreferenceCookie]);

	function parseContacts(contacts) {
		const headerReplacements = {
			type: 'Type',
			value: 'Value',
			note: 'Note',
			report_source: 'Source',
			created: 'Created',
			blocked: 'Blocked',
		};

		const columns = Object.keys(headerReplacements)
			.filter((key) => contacts.columns.includes(key))
			.map((key) => ({
				accessor: key,
				Header: headerReplacements[key]
			}));

		let rows = [];
		if (contacts.rows !== null) {
			rows = contacts.rows.map((currentValue) => {
				return currentValue.reduce((accumulator, currentValue, currentIndex) => {
					if (contacts.columns[currentIndex] === 'created') {
						accumulator[contacts.columns[currentIndex]] = new Date(currentValue).toLocaleString();
					} else {
						accumulator[contacts.columns[currentIndex]] = currentValue;
					}
					return accumulator;
				}, {});
			});
		}

		if (page > contacts.pages) {
			setPage(contacts.pages);
		} else if (page < 1) {
			setPage(1);
		}

		setStatistics({
			uniqueEmails: contacts.stats.EMAIL,
			uniquePhones: contacts.stats.PHONE,
			total: contacts.total
		});

		return {
			rows,
			columns,
			pages: contacts.pages
		};
	}

	const [myModal, setModal] = useState(false);
	const [myForm, setForm] = useState({});

	const openModal = () => {
		setForm({
			type: 'EMAIL',
			value: '',
			note: ''
		});
		setModal(true);
	}

	const formatTrProps = (state = {}) => {
		return {
			onClick: () => {
				setForm(state.original);
				setModal(true);
			}
		};
	};

	const handleSearchChange = (e) => {
		setSearchInput(e.target.value);
	};

	const handleColumnClick = (column) => {
		if (sortColumn === column) {
			setSortDesc((prevOrder) => !prevOrder);
		}
		setSortColumn(column);
	};

	//TODO: Add statistics - count of unique values by type.
	return (
		<div className={styles.fraudContactRoot}>
			<h1 className="fraud_contacts">Fraud Contacts</h1>
			<div className={styles.tableRoot}>
				<div className={styles.filterBar}>
					<button type='button' onClick={openModal} style={{ float: "none" }}>Add New Contact</button>

					<input
						type="text"
						value={searchInput}
						onChange={handleSearchChange}
						placeholder="Search..."
						className={styles.searchBox}
					/>

					<div className={styles.filterGroup}>
						<label htmlFor="pageSize">Page Size: </label>
						<select
							id="pageSize"
							value={pageSize}
							onChange={(e) => setPageSize(Number(e.target.value))}
						>
							<option value={10}>10</option>
							<option value={50}>50</option>
							<option value={100}>100</option>
							<option value={500}>500</option>
							<option value={0}>All</option>
						</select>
					</div>

					<div className={styles.filterGroup}>
						<button
							type="button"
							onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
							disabled={page === 1}
						>
							Previous
						</button>
						<span style={{ margin: '0 10px' }}>Page {page} of {contacts.pages}</span>
						<button
							type="button"
							onClick={() => setPage((prevPage) => prevPage + 1)}
							disabled={page === contacts.pages}
						>
							Next
						</button>
					</div>
				</div>
				<div className={styles.statistics}>
					<p><strong>Unique Emails:</strong> {statistics.uniqueEmails.toLocaleString()}</p>
					<p><strong>Unique Phones:</strong> {statistics.uniquePhones.toLocaleString()}</p>
					<p><strong>Total Contacts:</strong> {statistics.total.toLocaleString()}</p>
				</div>
				{myModal && <Modal setModal={setModal} myForm={myForm} setForm={setForm} fetchContacts={fetchContacts} />}
				<Table
					rows={contacts.rows}
					columns={contacts.columns}
					formatRowProps={(state) => formatTrProps(state)}
					onColumnClick={handleColumnClick}
				/>
			</div>
		</div>
	)
}

export default FraudContacts;
