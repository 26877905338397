//import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import styles from './Table.module.css';

const Table = (props) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		columns: props.columns,
		data: props.rows
	});

	
	return (
		<table {...getTableProps()} className={styles.fraud_contacts_table}>
			<thead>
				{
					headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{
								headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()} onClick={() => props.onColumnClick(column.id)} >
										{column.render('Header')}
										<span>
											{column.isSorted ? (column.isSortedDesc ? '⬇️' : '⬆️'): ''}
										</span>
									</th>
								))
							}
						</tr>
					))
				}
			</thead>
			<tbody {...getTableBodyProps()}>
				{
					rows.map(row => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps(props.formatRowProps && props.formatRowProps(row))}>
								{
									row.cells.map(cell => {
										return (
											<td 
												{...cell.getCellProps()} 
												className={styles.centered}
												title={cell.value}
											>
												{cell.render('Cell')}
											</td>
										)
									})
								}
							</tr>
						)
					})
				}
			</tbody>
		</table>
	)
}


export default Table